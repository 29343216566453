import { useEffect, useRef, useState, useContext } from 'react'
import { useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, Typography } from 'components/Contentful'
import Section from 'components/Section'
import styles from './HomeHeroV2.module.scss'
import { Grid, Box, Link, Paper, Slider, AchieveTheme } from '@achieve/ascend'
import classNames from 'classnames'
import { ArrowRight } from 'react-feather'
import { Eyebrow } from 'components/Eyebrow'
import { BLOCKS } from '@contentful/rich-text-types'
import { IconListItem } from 'components/IconListItem'
import { HandwrittenText } from 'components/HandwrittenText'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'
import { BREAKPOINTS } from 'constants/viewport'
import { unslug } from 'utils/routes'
import { useRouter } from 'next/router'
import { SubnavSectionModule } from 'components/SubnavSection'
import { AchieveLink } from 'components/AchieveLink'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import useLayoutMenuCondensed from 'hooks/useLayoutMenuCondensed'
import { LayoutContext } from 'providers/LayoutProvider'
import { Phone } from 'react-feather'
import { CtaCallButton } from 'components/CtaCallButton'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { DirectMailerLink } from 'components/DirectMailerLink'
import { removeUrlParams } from 'utils/shared'
import { GET_STARTED_ROUTE_DR } from 'constants/navigation'

/*
Home hero configs
{
  "color": "white",
  "contentBackground": "blue",
  "backgroundObjectPosition": "right",
  "backgroundObjectPositionMobile": "top",
  "heroVariant": "home",
}
*/

/*
Debt consolidation hero configs
{
  "backgroundObjectPosition": "right bottom",
  "backgroundObjectPositionMobile": "center",
  "color": "white",
  "contentBackground": "blue",
  "heroVariant": "debt-consolidation",
  "textAlign": {
      "mobile": "center",
      "desktop": "center"
  }
}
 */

function HomeHeroV2({ content }) {
  const {
    background,
    backgroundConfigJson: { jsonContent },
    button,
    eyebrow,
    handwrittenImg,
    iconListItems,
    link,
    subTitle,
    title,
    subnav,
    sliderConfig,
    directMailerLink,
    event,
  } = useMemoizedContentGetter(content, [
    'background',
    'backgroundConfigJson',
    'button',
    'eyebrow',
    'handwrittenImg',
    'iconListItems',
    'link',
    'subTitle',
    'title',
    'subnav',
    'sliderConfig',
    'directMailerLink',
    'event',
  ])

  const hasPhone = content?.fields?.additionalConfiguration?.fields?.jsonContent?.phone
  const phoneWrapper = content?.fields?.additionalConfiguration?.fields?.jsonContent?.phoneWrapper

  let newButton
  if (phoneWrapper) {
    newButton = { ...button }
    newButton.linkText.json.content[0].content[0].value = 'Get Started'
  }

  return (
    <HomeHeroV2Module
      background={background}
      backgroundImageAlt={background?.mediaAltText}
      isMobileUA={content?.isMobileUA}
      eyebrow={eyebrow}
      title={title}
      subTitle={subTitle}
      link={link}
      button={phoneWrapper ? newButton : button}
      jsonConfig={jsonContent}
      handwrittenImg={handwrittenImg}
      iconListItems={iconListItems}
      subnav={subnav}
      sliderConfig={sliderConfig}
      hasPhone={hasPhone}
      phoneWrapper={phoneWrapper}
      directMailerLink={directMailerLink}
      event={event}
    />
  )
}

function HomeHeroV2Module({
  background,
  eyebrow,
  title,
  subTitle,
  link,
  button,
  jsonConfig,
  handwrittenImg,
  iconListItems,
  subnav,
  sliderConfig,
  hasPhone = false,
  phoneWrapper = false,
  directMailerLink,
  event,
}) {
  const [menuCondensed] = useLayoutMenuCondensed()
  const [tabsOffset, setTabsOffset] = useState(54)
  const phoneData = useContext(PhoneDataContext)
  /// Get all the config values from the jsonConfig
  const {
    heroVariant,
    mobilePadding = 'bottom',
    backgroundObjectPosition = 'right',
    backgroundObjectPositionMobile = 'bottom',
    titleVariantMobile = 'headingLg',
    titleVariant = 'displayXl',
    subTitleVariantMobile = 'bodyLg',
    subTitleVariant = 'bodyLg',
    showPattern = false,
    contentBackground,
    textAlign,
  } = jsonConfig

  const directMailerFlag = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_DIRECT_MAIL_LINK)
  const { state: { isMobileUA } = {} } = useContext(LayoutContext)
  const isMobileGeneral = useSsrViewportCheck(isMobileUA, BREAKPOINTS.xl)
  const isMobileDebt = useSsrViewportCheck(isMobileUA, BREAKPOINTS.lg)
  const router = useRouter()

  const textContainerRef = useRef(null)

  const isDcVariation = heroVariant === 'debt-consolidation'

  const backgroundConfig = jsonConfig?.background ?? 'bgDefault'
  let contentDirection = 'row'
  let contentDirectionMobile = 'column'

  if (backgroundObjectPosition === 'left') {
    contentDirection = 'row-reverse'
  }

  if (backgroundObjectPositionMobile === 'top') {
    contentDirectionMobile = 'column-reverse'
  }

  // define variant for text based on mobile or desktop and json file
  const isMobile = isDcVariation ? isMobileDebt : isMobileGeneral

  useEffect(() => {
    // const DEFAULT_MOBILE_TOP_POSITION = menuCondensed ? 54: 54
    // const DEFAULT_DESKTOP_TOP_POSITION = menuCondensed ? 64: 64
    if (isMobile) {
      setTabsOffset(menuCondensed ? 54 : 72)
    } else {
      setTabsOffset(menuCondensed ? 64 : 64)
    }
  }, [isMobile, menuCondensed])

  const sliderProps = {
    ...sliderConfig?.jsonContent,
    ctaText: button?.linkText?.json?.content?.[0]?.content[0]?.value || 'Get started',
    ctaLinkBase: button?.linkHref || GET_STARTED_ROUTE_DR,
    ctaEvent: button?.event,
  }

  return (
    <section className={styles['subnav-outer-container']}>
      {subnav && (
        <Grid
          container
          style={{ top: tabsOffset }}
          className={styles['subnav-container']}
          data-testid="subnav-container"
        >
          <div>
            <SubnavSectionModule grid={subnav?.sectionContents[0]?.fields} />
          </div>
        </Grid>
      )}
      <Section
        className={styles['section']}
        data-testid="closer-fullbg-section"
        data-narrow={true}
        data-hero-variant={heroVariant}
        mediaContent={background?.mediaContent}
        middleMediaContent={background?.mobileMediaContent}
        mobileMediaContent={background?.mobileMediaContent}
        backgroundImageAlt={background?.mediaAltText}
        showPattern={showPattern}
        backgroundObjectPosition={
          isMobile ? backgroundObjectPositionMobile : backgroundObjectPosition
        }
        variant={backgroundConfig}
        preloadBackground
      >
        <Box
          className={classNames(styles['fullbg-content'], styles[mobilePadding])}
          component="div"
        >
          <Grid
            container
            direction={isMobile ? contentDirectionMobile : contentDirection}
            alignItems="center"
            data-testid="closer-fullbg-outer-grid-container"
          >
            {/* The content grid item */}
            <Grid
              item
              className={styles['grid-item-text']}
              data-testid="closer-fullbg-grid-item-text"
              container
            >
              <div
                className={styles['text-container']}
                ref={textContainerRef}
                style={{
                  textAlign: isMobile
                    ? textAlign?.mobile ?? 'center'
                    : textAlign?.desktop ?? 'left',
                }}
                data-container-color={contentBackground}
                data-testid="closer-fullbg-text-container"
              >
                {eyebrow && (
                  <Eyebrow
                    content={eyebrow?.textContent}
                    fontWeight="bold"
                    component="h1"
                    className={classNames(
                      styles['eyebrow'],
                      {
                        [styles[`font-${eyebrow?.fontColor?.toLowerCase()}`]]: Boolean(
                          eyebrow?.fontColor
                        ),
                      },
                      { [styles[`font-align-${eyebrow?.position}`]]: Boolean(eyebrow?.position) }
                    )}
                  />
                )}
                {title && (
                  <Typography
                    content={title?.textContent}
                    variant={titleVariant}
                    mobileVariant={titleVariantMobile}
                    breakpoint="md"
                    responsive={true}
                    fontWeight="bold"
                    className={classNames(
                      styles[`${eyebrow?.textContent ? 'title' : 'title-no-eyebrow'}`],
                      styles[`font-${title?.fontColor?.toLowerCase()}`]
                    )}
                  />
                )}
                {subTitle && (
                  <Typography
                    content={subTitle?.textContent}
                    variant={subTitleVariant}
                    mobileVariant={subTitleVariantMobile}
                    breakpoint={isDcVariation ? 'lg' : 'md'}
                    responsive={true}
                    className={classNames(
                      styles['sub-title'],
                      styles[`font-${subTitle?.fontColor?.toLowerCase()}`]
                    )}
                  />
                )}
                {iconListItems?.gridItems?.length > 0 && (
                  <div className={styles['icon-container']}>
                    <ul>
                      {iconListItems.gridItems.map((icon, index) => {
                        return (
                          <IconListItem
                            iconColor={icon.fields.iconColor}
                            iconName={icon.fields.iconName}
                            iconSize={isMobile ? 20 : 30}
                            itemText={icon.fields.description}
                            key={`icon-list-item-${index}`}
                            testIdPrefix="home-hero"
                            textColor={AchieveTheme?.ascend?.colors?.neutral?.[0]}
                            variant={isMobile ? 'bodySm' : 'headingMd'}
                          />
                        )
                      })}
                    </ul>
                  </div>
                )}
                {link && !sliderConfig && (
                  <Link underline="hover" fontWeight="bold" variant="bodyLg" href={link?.linkHref}>
                    <Typography
                      content={link?.linkText?.json}
                      fontWeight="bold"
                      className={styles['text-link']}
                      variant="bodyLg"
                      mobileVariant="bodyLg"
                      breakpoint="md"
                      responsive={true}
                    />{' '}
                    <ArrowRight className={styles['svg-link']} />
                  </Link>
                )}
                {button && !sliderConfig && (
                  <CtaCallButton phoneWrapper={phoneWrapper} variant="white">
                    <LinkButton
                      track={{
                        ...{
                          list_name: 'Home Hero CTA click',
                          nav_link_section: `${
                            removeUrlParams(router.asPath) === '/'
                              ? 'Home'
                              : unslug(router.asPath).slice(1)
                          } Hero`,
                          click_id: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                          click_text: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                          click_url: button?.linkHref,
                        },
                        ...button.event,
                      }}
                      typographicOptions={{
                        variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                        fontWeight: 'bold',
                      }}
                      variant="contained"
                      color="secondary"
                      content={button}
                      className={styles['cta-button']}
                      underline="hover"
                    />
                  </CtaCallButton>
                )}
                {sliderConfig && <SliderTool {...sliderProps} title={'titleText'} />}

                {directMailerFlag && directMailerLink && (
                  <DirectMailerLink
                    content={directMailerLink}
                    track={{
                      list_name: 'Home Hero Direct Mailer Click',
                      nav_link_section: `${
                        removeUrlParams(router.asPath) === '/'
                          ? 'Home'
                          : unslug(router.asPath).slice(1)
                      } Hero`,
                      click_id: directMailerLink?.linkText?.json?.content?.[0].content?.[0].value,
                      click_text: directMailerLink?.linkText?.json?.content?.[0].content?.[0].value,
                      click_url: directMailerLink.linkHref,
                    }}
                  />
                )}

                {hasPhone && phoneData.phoneNumber && (
                  <AchieveLink
                    className={styles['phone-link']}
                    href={`tel:${phoneData.phoneNumber}`}
                    noLink
                    track={{
                      ...{
                        list_name: 'Hero V2 Phone',
                        click_id: phoneData.phoneNumber,
                        click_text: `Achieve Web | ${phoneData.phoneNumber}`,
                      },
                      ...event,
                    }}
                    underline="hover"
                  >
                    <div className={styles['phone-container']}>
                      {/* Empty div to ignore !important css making phone image 345px tall */}
                      <div></div>
                      <Phone
                        alt="phone button"
                        width={24}
                        height={24}
                        color={AchieveTheme?.ascend?.colors?.neutral?.[0]}
                      />
                      <Typography
                        fontWeight="bold"
                        variant="bodyLg"
                        mobileVariant="bodyLg"
                        breakpoint="md"
                        responsive={true}
                        className={styles['phone-text']}
                        content={phoneData.phoneNumber}
                      />
                    </div>
                  </AchieveLink>
                )}
              </div>

              {handwrittenImg && (
                <div
                  className={styles['handwritten-text-container']}
                  data-hero-variant={heroVariant}
                >
                  <div className={styles['handwritten-text']} data-hero-variant={heroVariant}>
                    <HandwrittenText
                      alt={handwrittenImg?.mediaAltText}
                      content={handwrittenImg?.mediaContent}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Section>
    </section>
  )
}

function SliderTool({ ctaEvent, ctaText, ctaLinkBase, slider, sliderQueryParam }) {
  const [sliderValue, setSliderValue] = useState(
    slider.defaultValue ? slider.defaultValue : slider.max / 2
  )
  const [ctaHref, setCtaHref] = useState('')
  const dollarUSLocale = Intl.NumberFormat('en-US')

  useEffect(() => {
    setCtaHref(`${ctaLinkBase}&${sliderQueryParam}=${sliderValue}`)
  }, [sliderValue, ctaLinkBase, sliderQueryParam])

  useEffect(() => {
    setSliderValue(slider.defaultValue ? slider.defaultValue : slider.max / 2)
  }, [slider])

  function handleChange(e) {
    setSliderValue(e.target.value)
  }

  return (
    <Paper className={styles['slider-tool-paper']} data-testid="product-hero-slider-container">
      <Grid container>
        <Grid item xs={12}>
          <div className={styles['slider-tool-wrapper']}>
            <Typography
              content={`$${dollarUSLocale.format(sliderValue)}${
                sliderValue === slider.max && slider.maxPlus ? '+' : ''
              }`}
              variant="bodyLg"
              mobileVariant="bodyLg"
              breakpoint="md"
              responsive={true}
              fontWeight="medium"
              className={styles['slider-tool-value']}
              data-testid="product-hero-dollar-value"
            />
            <Slider
              aria-label="dollar-amount-selection-slider"
              className={styles['slider-tool-slider']}
              classes={{
                mark: styles['marks'],
              }}
              data-testid="product-hero-slider-input"
              value={sliderValue}
              step={slider.step}
              min={slider.min}
              max={slider.max}
              marks={[
                {
                  value: slider.min,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.min)}`}
                      variant="bodyLg"
                      mobileVariant="bodyLg"
                      breakpoint="md"
                      responsive={true}
                      fontWeight="medium"
                      className={styles['slider-tool-min-mark']}
                      data-testid="product-hero-slider-min"
                    />
                  ),
                },
                {
                  value: slider.max,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.max)}${slider.maxPlus ? '+' : ''}`}
                      variant="bodyLg"
                      mobileVariant="bodyLg"
                      breakpoint="md"
                      responsive={true}
                      fontWeight="medium"
                      className={styles['slider-tool-max-mark']}
                      data-testid="product-hero-slider-max"
                    />
                  ),
                },
              ]}
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <LinkButton
            track={{
              ...{
                list_name: 'Home Hero CTA click',
                click_id: ctaText,
                click_text: ctaText,
                form_ss_amount: sliderValue,
                click_url: ctaHref,
              },
              ...ctaEvent,
            }}
            content={{
              linkHref: ctaHref,
              linkText: ctaText,
            }}
            typographicOptions={{ variant: 'bodyLg', fontWeight: 'bold' }}
            variant="contained"
            color="secondary"
            className={styles['slider-tool-cta']}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export { HomeHeroV2, HomeHeroV2Module }
export default HomeHeroV2
